import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrintInfoPage = () => {
  return (
    <Layout>
      <SEO title="Wydruk" />

      <div className="regulaminContent proza">
        <h1>Metoda wydruku</h1>
        <h2>CO TO JEST WYDRUK PIGMENTOWY?</h2>
        <p>
          Druk pigmentowy to inaczej druk artystyczny. Plotery, z których
          korzystam, oparte są na znacznie szerszej gamie barw niż standardowe
          drukarki. Korzystają z jedenastu tuszy pigmentowych, podczas gdy te
          drugie mieszają jedynie cztery kolory. Dzięki temu, projektując swoje
          prace w szerokiej przestrzeni barw AdobeRGB, jestem w stanie uzyskać
          delikatniejsze przejścia tonalne i zdecydowanie żywsze kolory.
          Technika ma również swoje zalety przy wydruku czarno-białym. Czerń
          uzyskuje się z gradacji szarych i czarnych tuszy, dzięki czemu jest
          ona zdecydowanie intensywniejsza niż w druku cyfrowym. Wydruk
          pigmentowy jest również idealnie gładki i trwały - atramenty
          pigmentowe mogą przetrwać nawet 200 lat!
        </p>
        <p>
          Nie drukuję plakatów na kolorowych aplach - jeśli plakat ma kolorowe
          tło to znaczy, że prawie cały karton pokryty jest tuszem.{" "}
        </p>
        <h2>GDZIE KUPIĘ RAMĘ ABY OPRAWIĆ PLAKAT?</h2>
        <p>
          Moje prace drukuję w standardowych formatach, zgodnych z
          międzynarodową normą ISO 216. Kupicie u mnie grafiki w formatach
          B1(100x70cm) oraz B2(50x70cm). Oprawić je możecie w dedykowanych do
          tego pracowniach lub po prostu kupić ramę w sieciówkach typu Leroy
          Merlin lub Ikea. Osobiście polecam ramy szklane (mniej te z pleksi).
          Plakat oprawiony w szkło zyskuje dodatkowej głębi:){" "}
        </p>
      </div>
    </Layout>
  )
}

export default PrintInfoPage
